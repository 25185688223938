import { Document } from '../apis/entities/document.entity'
// const RELATION_THRESHOLD = 0.9 todo: reimplement this

// const _ = {
//   dropWhile: function (array: any[], predicate: any) {
//     const sliceIndex = array.findIndex((element) => !predicate(element))
//     return array.slice(sliceIndex)
//   },
// }

// This function flattens a hierarchical document structure into a flat list
function flattenDocuments(docs: Document[]): Document[] {
  let flatDocs: Document[] = []
  docs.forEach((doc) => {
    flatDocs.push(doc)
    if (doc.children) {
      const children = doc.children
      doc.children = []
      flatDocs = [...flatDocs, ...flattenDocuments(children)]
    }
  })
  return flatDocs
}
// this([h, g, f, e, d, c, b, a], [d, c, b, a]) === 4
function findCommonElementsFromEnd(arr1: string[], arr2: string[]) {
  for (let i = 0; i < Math.min(arr1.length, arr2.length); i++) {
    const el1 = arr1[arr1.length - i - 1]
    const el2 = arr2[arr2.length - i - 1]
    if (el1 !== el2) {
      return i
    }
  }
  return Math.min(arr1.length, arr2.length)
}
// rebuild children[], parents[] from parentIds[]
export function rebuildTree(docs: Document[]): Document[] {
  // Sort documents from oldest to newest based on their createdAt property
  docs = docs.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  )
  docs = docs.map((doc) => ({
    ...doc,
    children: [],
    parents: [],
  }))
  const docMap = new Map(docs.map((doc) => [doc.id, doc]))

  // Set children and update parents array
  for (const doc of docs) {
    if (doc._TEMP_PARENT_ID) {
      let parentDoc = docMap.get(doc._TEMP_PARENT_ID)
      if (parentDoc) {
        parentDoc.children.push(doc)
      }
      while (parentDoc) {
        doc.parents.push(parentDoc)

        parentDoc = parentDoc._TEMP_PARENT_ID
          ? docMap.get(parentDoc._TEMP_PARENT_ID)
          : undefined
      }
    }
    // for (const parentId of doc._TEMP_parentIds) {
    //   const parentDoc = docMap.get(parentId)
    //   if (parentDoc) {
    //     // todo: reintroduce RELATION_THRESHOLD check OR reindex all topics in database
    //     // if ((doc.parentSimilarity || 0) >= RELATION_THRESHOLD) {
    //
    //     // Add to document's parents
    //     doc.parents.push(parentDoc)
    //   }
    // }
    // for (const parentId of doc._TEMP_parentIds) {
    //   const parentDoc = docMap.get(parentId)
    //   if (parentDoc) {
    //     parentDoc.children.push(doc)
    //     // only apply to first parent
    //     break
    //   }
    // }
  }

  // set parents
  // for (const doc of docs) {
  //   for (const parentId of doc.parentIds) {
  //     const parentDoc = docMap.get(parentId)
  //     if (parentDoc) {
  //       // @ts-ignore
  //       doc.parents.push(parentDoc)
  //     }
  //   }
  // }

  return docs
}

// .parents[] and .children[] will be INVALID after this
// call rebuildTree(docs) to rebuild .parents[] and .children[] BEFORE and AFTER
export function deleteTopic(docs: Document[], idToDelete: string): Document[] {
  // docs = docs.map((doc) => ({
  //   ...doc,
  //   // _TEMP_parentIds: doc.parentIds,
  // }))
  docs = docs.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  )
  const toDelete = docs.find((doc) => doc.id === idToDelete)
  // if (!toDelete) {
  //   return docs // If root not found, exit
  // }

  // Get all direct children of the root document
  const directChildren = docs.filter(
    (doc) => doc._TEMP_PARENT_ID === idToDelete,
  )
  // && (doc.parentSimilarity || 0) >= RELATION_THRESHOLD,

  if (
    toDelete &&
    toDelete._TEMP_PARENT_ID &&
    docs.find((doc) => doc.id === toDelete._TEMP_PARENT_ID)
    // && (toDelete.parentSimilarity || 0) >= RELATION_THRESHOLD)
  ) {
    // If the root document has a parent, update the parentId for
    // all direct children to the root's parent
    directChildren.forEach((childDoc) => {
      // childDoc.parentIds = _.dropWhile(
      //   childDoc.parentIds,
      //   (id: string) => id === idToDelete,
      // )
      // childDoc.parents = childDoc.parents.filter((x) => x.id !== idToDelete)
      // childDoc._TEMP_parentIds = childDoc._TEMP_parentIds.filter(
      //   (x) => x !== idToDelete,
      // )
      childDoc._TEMP_PARENT_ID = toDelete._TEMP_PARENT_ID
      // childDoc.parentIds[0] = toDelete.parentIds[0]
    })
  } else {
    // Sort children by createdAt to find the oldest
    directChildren.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    )
    // Identify the oldest child to become the new root, if any exist
    const newRoot = directChildren.shift() // This removes the oldest child from the array

    if (newRoot) {
      // Update first parentId for the remaining children to point to the new root
      directChildren.forEach((doc) => {
        doc._TEMP_PARENT_ID = newRoot.id
        // newRoot.children?.push(x)
      })

      // find new parent for newRoot
      let maxCommon = 0
      newRoot._TEMP_PARENT_ID = null
      // for (let i = 0; i < docs.indexOf(newRoot); i++) {
      //   const doc = docs[i]
      //   // Use doc here (e.g., console.log(doc))
      // }

      for (const doc of docs) {
        if (doc.id === newRoot.id) {
          break
        }

        // // this([h, g, f, e, d, c, b, a], [d, c, b, a]) === 4
        // function findCommonElementsFromEnd(arr1: string[], arr2: string[]) {
        // for (let i = 0; i < Math.min(doc.parentIds.length, newRoot.parentIds.length); i++) {
        //   const el1 = doc.parentIds[doc.parentIds.length - i - 1]
        //   const el2 = newRoot.parentIds[newRoot.parentIds.length - i - 1]
        //   if (el1 !== el2) {
        //     maxCommon = i
        //     break
        //   }
        // }
        //   return Math.min(arr1.length, arr2.length)
        // }

        if (doc.parentIds.length >= newRoot.parentIds.length) {
          // a parent cannot be deeper in tree than child
          continue
        }
        const commonAncestors = findCommonElementsFromEnd(
          doc.parentIds,
          newRoot.parentIds,
        )
        // if (newRoot.id === '_D2-1-1-1-3') {
        //   const x = 1
        //   if (doc.id === '_D2-1-1-1-2-1') {
        //     // doc = [_D2-1-1-1-2, _D2-1-1-1, _D2-1-1, _D2-1, _D2, _D-ZERO]
        //     // newRoot =          [_D2-1-1-1, _D2-1-1, _D2-1, _D2, _D-ZERO]
        //     const x = 1
        //   }
        //   if (doc.id === '_D2-1-1') {
        //     // doc =                         [_D2-1, _D2, _D-ZERO]
        //     // newRoot = [_D2-1-1-1, _D2-1-1, _D2-1, _D2, _D-ZERO]
        //     const x = 1
        //   }
        // }
        //   if (doc.id === '_D2-1-1-1-2-1') {
        //     let x = 1
        //     if (newRoot.id === '_D2-1-1-1-2-1-1-1') {
        //       let x = 1
        //       const y = findCommonElementsFromEnd(
        //         doc.parentIds,
        //         newRoot.parentIds,
        //       )
        //       x = 1
        //     }
        //   }
        if (commonAncestors > maxCommon) {
          maxCommon = commonAncestors
          newRoot._TEMP_PARENT_ID = doc.id
        }
        // console.log(`id = ${doc.id}, commonAncestors = ${commonAncestors}`)
      }
    }
  }

  return docs.filter((doc) => doc.id !== idToDelete)
}

// this one sorts into recursive nested heirarchy AND filters by published status
export function getFamV4(
  docs: Document[],
  // filterPublished: boolean | null,
  // filterDate: Date | undefined,
  // filterPublishedBy: string,
  // filterIndustry: string,
  // filterIsTopic: boolean | null,
  // filterClientSpecific: boolean | null,
  useNesting: boolean,
) {
  console.log('getFamV4', docs.length)
  // Sort documents from oldest to newest based on their createdAt property
  docs.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  )
  // todo: these filters not necessary. delete them
  // Filter based on publish status if specified
  // if (filterPublished !== null) {
  //   docs = docs.filter((doc) => doc.published === filterPublished)
  // }
  //
  // if (filterDate) {
  //   docs = docs.filter((doc) => new Date(doc.createdAt) > filterDate)
  // }
  //
  // if (filterPublishedBy) {
  //   docs = docs.filter((doc) => doc.publishedAuth0Id === filterPublishedBy)
  // }
  //
  // if (filterIndustry) {
  //   if (filterIndustry === '__IS_NULL__') {
  //     docs = docs.filter((doc) => !doc.industry)
  //   } else {
  //     docs = docs.filter((doc) => doc.industry === filterIndustry)
  //   }
  // }
  //
  // if (filterIsTopic !== null)
  //   docs = docs.filter((doc) => doc.isTopic === filterIsTopic)
  // if (filterClientSpecific !== null)
  //   docs = docs.filter((doc) => doc.clientSpecific === filterClientSpecific)

  docs = docs.map((doc) => ({
    ...doc,
    children: [],
    parents: [],
  }))

  // Create a map for quick ID to document lookup
  const docMap = new Map(docs.map((doc) => [doc.id, doc]))

  const missingIds = new Set<string>()

  // assign grandparent to orphaned children
  docs.forEach((doc) => {
    // if a parentId is missing from docMap
    // add the missing parentId to the missingIds set
    if (doc.deletedAt) {
      // might not be necessary???
      missingIds.add(doc.id)
    }

    if (
      //   // (doc.parentSimilarity || 0) >= RELATION_THRESHOLD &&
      doc.parentIds[0] &&
      !docMap.has(doc.parentIds[0])
    ) {
      missingIds.add(doc.parentIds[0])
    }

    // for (const parentId of doc.parentIds) {
    //   if (!docMap.has(parentId)) {
    //     missingIds.add(parentId)
    //   }
    // }
  })

  missingIds.forEach((id) => {
    docs = deleteTopic(docs, id)
  })

  docs = rebuildTree(docs)

  // Filter out master documents (those with no parents) to identify top-level documents
  const masterDocs = docs.filter((doc) => doc.parents?.length === 0)

  if (!useNesting) {
    for (const doc of masterDocs) {
      doc.children = flattenDocuments(doc.children || [])
    }
  }

  return { docs, masterDocs, missingIds }
}

export const DatasetSmall: Document[] = [
  {
    id: '_D-ZERO',
    name: 'ZERO',
    createdAt: '2023-11-13T01:43:45.985Z',
    updatedAt: '2023-11-13T01:44:29.432Z',
    published: false,
    filename: 'Course overview advanced analytics upskilling program  (1).docm',
    topic: 'ZERO',
    subtitle: 'Course overview  ',
    publishedAuth0Id: 'auth0|64b86cf05b9d5e1549852a16',
    publishedUserName: 'Liz Smith',
    isTopic: false,
    clientSpecific: true,
    isLocked: false,
    parentIds: [],
  },
  {
    id: '_D1',
    name: 'Advanced analytics upskilling program - Course overview  ',
    createdAt: '2023-11-14T05:42:27.442Z',
    updatedAt: '2023-11-14T05:42:31.080Z',
    published: true,
    filename: 'Course overview advanced analytics upskilling program  (2).docm',
    topic: '1st child',
    subtitle: 'Course overview  ',
    publishedAuth0Id: 'auth0|652cadbb65c5cbd70bf7b4ed',
    publishedUserName: 'Eva Zhang',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D-ZERO'],
  },
  {
    id: '_D2',
    name: 'Advanced analytics upskilling program - Course overview  ',
    createdAt: '2023-11-16T05:04:58.020Z',
    updatedAt: '2023-11-16T05:05:28.189Z',
    published: true,
    filename: 'Course overview advanced analytics upskilling program  (5).docm',
    topic: '2nd child',
    subtitle: 'Course overview  ',
    publishedAuth0Id: 'auth0|652cadbb65c5cbd70bf7b4ed',
    publishedUserName: 'Eva Zhang',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D-ZERO'],
  },
  {
    id: '_D3',
    name: 'Digital Leadership Program - Program overview  ',
    createdAt: '2023-11-23T23:39:10.470Z',
    updatedAt: '2023-11-23T23:40:03.925Z',
    published: true,
    filename: 'Banpu Program overview V1.docm',
    topic: '3rd child',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: true,
    clientSpecific: true,
    isLocked: false,
    parentIds: ['_D-ZERO'],
  },
  {
    id: '_D3-1',
    name: 'Digital Leadership Program - Program overview  ',
    createdAt: '2023-11-24T00:37:21.913Z',
    updatedAt: '2023-11-24T00:38:06.427Z',
    published: true,
    filename: 'Banpu Program overview V2.docm',
    topic: '3.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3', '_D-ZERO'],
  },
  {
    id: '_D3-2',
    name: 'Digital Leadership Program - Program overview  ',
    createdAt: '2023-12-05T04:23:28.848Z',
    updatedAt: '2023-12-05T04:23:58.918Z',
    published: true,
    filename: 'Program overview Banpu.docm',
    topic: '3.2',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3', '_D-ZERO'],
  },
  {
    id: '_D4',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2023-12-06T23:17:48.613Z',
    updatedAt: '2023-12-06T23:18:50.160Z',
    published: true,
    filename: 'Course 2 overview advanced analytics upskilling program .docm',
    topic: '4th child',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: true,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D-ZERO'],
  },
  {
    id: '_D3-2-1',
    name: 'Digital Leadership Program - Program overview  ',
    createdAt: '2023-12-20T23:22:26.541Z',
    updatedAt: '2023-12-20T23:22:30.524Z',
    published: true,
    filename: 'Program overview Banpu (7).docm',
    topic: '3.2.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|64b86cf05b9d5e1549852a16',
    publishedUserName: 'Liz Smith',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3-2', '_D3', '_D-ZERO'],
  },
  {
    id: '_D3-3',
    name: 'Digital Leadership Program  - Assessment Brief',
    createdAt: '2024-01-11T02:34:03.663Z',
    updatedAt: '2024-01-11T02:34:22.294Z',
    published: true,
    filename: 'Assessment Brief - LXE Banpu.docm',
    topic: '3.3',
    subtitle: 'Assessment Brief',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-1',
    name: 'Digital Leadership Program - Program overview  ',
    createdAt: '2024-01-11T20:51:09.285Z',
    updatedAt: '2024-01-11T20:51:16.465Z',
    published: true,
    filename: 'Program overview Banpu.docm',
    topic: '3.2.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3-2-1', '_D3-2', '_D3', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-2',
    name: 'Digital Leadership Program - Welcome to Course 2<p>Program overview</p>',
    createdAt: '2024-01-14T22:25:41.052Z',
    updatedAt: '2024-01-14T22:26:39.555Z',
    published: true,
    filename: 'Program overview Banpu - C2 home page.docm',
    topic: '3.2.1.2',
    subtitle: 'Welcome to Course 2<p>Program overview</p>',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3-2-1', '_D3-2', '_D3', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-2-1',
    name: 'Digital Leadership Program - Welcome to Course 2<p>Program overview</p>',
    createdAt: '2024-01-14T22:31:00.723Z',
    updatedAt: '2024-01-14T22:31:06.718Z',
    published: true,
    filename: 'Program overview Banpu - C2 home page.docm',
    topic: '3.2.1.2.1',
    subtitle: 'Welcome to Course 2<p>Program overview</p>',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3-2-1-2', '_D3-2-1', '_D3-2', '_D3', '_D-ZERO'],
    parentSimilarity: 1,
  },
  {
    id: '_D3-2-1-2-2',
    name: 'Digital Leadership Program - Program overview  <p> </p>',
    createdAt: '2024-01-14T23:19:25.720Z',
    updatedAt: '2024-01-14T23:19:55.468Z',
    published: true,
    filename: 'Program overview Banpu - C2 links.docm',
    topic: '3.2.1.2.2',
    subtitle: 'Program overview  <p> </p>',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3-2-1-2', '_D3-2-1', '_D3-2', '_D3', '_D-ZERO'],
  },
  {
    id: '_D4-1',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-15T00:08:52.639Z',
    updatedAt: '2024-01-15T00:09:10.962Z',
    published: true,
    filename: 'Course 2 overview advanced analytics upskilling program .docm',
    topic: '4.1',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D4', '_D-ZERO'],
  },
  {
    id: '_D4-1-1',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-15T00:14:30.658Z',
    updatedAt: '2024-01-15T00:14:35.621Z',
    published: true,
    filename: 'Course 2 overview advanced analytics upskilling program .docm',
    topic: '4.1.1',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D4-1', '_D4', '_D-ZERO'],
  },
  {
    id: '_D4-1-1-1',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-15T02:31:02.596Z',
    updatedAt: '2024-01-15T02:31:14.053Z',
    published: true,
    filename: 'Course 2 overview advanced analytics upskilling program.docm',
    topic: '4.1.1.1',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D4-1-1', '_D4-1', '_D4', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-17T04:55:55.831Z',
    updatedAt: '2024-01-17T04:56:04.168Z',
    published: true,
    filename: 'Program overview Banpu.docm',
    topic: '3.2.1.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D3-2-1-1', '_D3-2-1', '_D3-2', '_D3', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-1-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-17T05:31:15.509Z',
    updatedAt: '2024-01-17T05:31:21.325Z',
    published: true,
    filename: 'Program overview Banpu.docm',
    topic: '3.2.1.1.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-1-1',
      '_D3-2-1-1',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    id: '_D3-2-1-2-2-1',
    name: 'Digital leadership program - Program overview  <p> </p>',
    createdAt: '2024-01-17T20:10:30.950Z',
    updatedAt: '2024-01-17T20:10:36.969Z',
    published: true,
    filename: 'Program overview Banpu - C2 links.docm',
    topic: '3.2.1.2.2.1',
    subtitle: 'Program overview  <p> </p>',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-2-2',
      '_D3-2-1-2',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    // 20
    id: '_D3-2-1-1-1-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-17T20:16:06.738Z',
    updatedAt: '2024-01-17T20:16:12.417Z',
    published: true,
    filename: 'Program overview Banpu.docm',
    topic: '3.2.1.1.1.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-1-1-1',
      '_D3-2-1-1-1',
      '_D3-2-1-1',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    id: '_D4-1-1-2',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-18T02:53:10.882Z',
    updatedAt: '2024-01-18T02:53:15.897Z',
    published: true,
    filename:
      'Course 2 overview advanced analytics upskilling program  (1).docm',
    topic: '4.1.1.2',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D4-1-1', '_D4-1', '_D4', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-1-1-1-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-19T03:20:25.909Z',
    updatedAt: '2024-01-19T03:20:33.893Z',
    published: true,
    filename: 'Program overview Banpu.docm',
    topic: '3.2.1.1.1.1.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-1-1-1-1',
      '_D3-2-1-1-1-1',
      '_D3-2-1-1-1',
      '_D3-2-1-1',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    id: '_D3-2-1-1-1-1-1-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-19T03:33:58.606Z',
    updatedAt: '2024-01-19T03:34:39.429Z',
    published: true,
    filename: 'Program overview Banpu.docm',
    topic: '3.2.1.1.1.1.1.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-1-1-1-1-1',
      '_D3-2-1-1-1-1-1',
      '_D3-2-1-1-1-1',
      '_D3-2-1-1-1',
      '_D3-2-1-1',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    id: '_D3-2-1-2-2-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-19T04:56:26.936Z',
    updatedAt: '2024-01-19T04:56:32.284Z',
    published: true,
    filename: 'Program overview Banpu - C2.docm',
    topic: '3.2.1.2.2.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-2-2-1',
      '_D3-2-1-2-2',
      '_D3-2-1-2',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    id: '_D4-1-1-2-1',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-24T23:32:37.837Z',
    updatedAt: '2024-01-24T23:32:56.073Z',
    published: true,
    filename: 'Course 2 overview advanced analytics upskilling program.docm',
    topic: '4.1.1.2.1',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D4-1-1-2', '_D4-1-1', '_D4-1', '_D4', '_D-ZERO'],
  },
  {
    id: '_D4-1-1-2-1-1',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-25T05:30:07.895Z',
    updatedAt: '2024-01-25T05:30:13.574Z',
    published: true,
    filename: 'Course 2 overview advanced analytics upskilling program.docm',
    topic: '4.1.1.2.1.1',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D4-1-1-2-1',
      '_D4-1-1-2',
      '_D4-1-1',
      '_D4-1',
      '_D4',
      '_D-ZERO',
    ],
  },
  {
    id: '_D3-2-1-2-2-1-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-29T00:36:50.121Z',
    updatedAt: '2024-01-29T00:36:55.734Z',
    published: true,
    filename: 'Program overview Banpu - C2.docm',
    topic: '3.2.1.2.2.1.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-2-2-1-1',
      '_D3-2-1-2-2-1',
      '_D3-2-1-2-2',
      '_D3-2-1-2',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    id: '_D4-1-1-3',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-30T20:31:25.485Z',
    updatedAt: '2024-01-30T20:31:32.883Z',
    published: true,
    filename: 'Course 2 overview advanced analytics upskilling program.docm',
    topic: '4.1.1.3',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D4-1-1', '_D4-1', '_D4', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-2-2-1-1-1-1',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-01-30T23:35:12.861Z',
    updatedAt: '2024-01-30T23:35:20.536Z',
    published: true,
    filename: 'Program overview Banpu - C3.docm',
    topic: '3.2.1.2.2.1.1.1.1',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-2-2-1-1-1',
      '_D3-2-1-2-2-1-1',
      '_D3-2-1-2-2-1',
      '_D3-2-1-2-2',
      '_D3-2-1-2',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
  },
  {
    id: '_D4-1-1-3-1',
    name: 'Advanced analytics upskilling program - Course 2 overview  ',
    createdAt: '2024-01-31T01:54:36.283Z',
    updatedAt: '2024-01-31T01:54:58.061Z',
    published: true,
    filename:
      'Course 2 overview advanced analytics upskilling program  updated.docm',
    topic: '4.1.1.3.1',
    subtitle: 'Course 2 overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D4-1-1-3', '_D4-1-1', '_D4-1', '_D4', '_D-ZERO'],
  },
  {
    id: '_D3-2-1-2-2-1-1-1-2',
    name: 'Digital leadership program - Program overview  ',
    createdAt: '2024-02-07T00:46:41.279Z',
    updatedAt: '2024-02-07T00:47:05.755Z',
    published: true,
    filename: 'Program overview Banpu - C4.docm',
    topic: '3.2.1.2.2.1.1.1.2',
    subtitle: 'Program overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D3-2-1-2-2-1-1-1',
      '_D3-2-1-2-2-1-1',
      '_D3-2-1-2-2-1',
      '_D3-2-1-2-2',
      '_D3-2-1-2',
      '_D3-2-1',
      '_D3-2',
      '_D3',
      '_D-ZERO',
    ],
    parentSimilarity: 1,
  },
  {
    id: '_D2-1',
    name: 'Advanced analytics upskilling program - Course overview  ',
    createdAt: '2024-02-12T22:21:24.007Z',
    updatedAt: '2024-02-12T22:21:33.666Z',
    published: true,
    filename:
      'Template C1 Overview advanced analytics upskilling program  - Copy.docm',
    topic: '2.1',
    subtitle: 'Course overview  ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D2', '_D-ZERO'],
  },
  {
    id: '_D2-1-1',
    name: 'Data analytics upskill program - Course overview ',
    createdAt: '2024-02-19T02:27:57.177Z',
    updatedAt: '2024-02-19T02:28:27.943Z',
    published: true,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1',
    subtitle: 'Course overview ',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D2-1', '_D2', '_D-ZERO'],
  },
  {
    id: '_D2-1-1-1',
    name: 'Data analytics upskill program - Course overview This course opens on 8 April 2024',
    createdAt: '2024-02-19T02:37:44.372Z',
    updatedAt: '2024-03-27T12:03:25.958Z',
    published: false,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1.1',
    subtitle: 'Course overview This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D2-1-1', '_D2-1', '_D2', '_D-ZERO'],
  },
  {
    id: '_D2-1-1-1-1',
    name: 'Data analytics upskill program - Course overview This course opens on 8 April 2024',
    createdAt: '2024-02-19T02:38:59.073Z',
    updatedAt: '2024-03-27T12:03:25.958Z',
    published: false,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1.1.1',
    subtitle: 'Course overview This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D2-1-1-1', '_D2-1-1', '_D2-1', '_D2', '_D-ZERO'],
    parentSimilarity: 1,
  },
  {
    id: '_D2-1-1-1-2',
    name: 'Data analytics upskill program - Course overviewdescription: This course opens on 8 April 2024',
    createdAt: '2024-02-19T02:40:34.597Z',
    updatedAt: '2024-03-27T12:03:25.958Z',
    published: false,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1.1.2',

    subtitle: 'Course overviewdescription: This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D2-1-1-1', '_D2-1-1', '_D2-1', '_D2', '_D-ZERO'],
  },
  {
    id: '_D2-1-1-1-2-1',
    name: 'Data analytics upskill program - This course opens on 8 April 2024',
    createdAt: '2024-02-19T02:41:35.276Z',
    updatedAt: '2024-02-19T02:41:44.494Z',
    published: true,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1.1.2.1',
    subtitle: 'This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-3',
    name: 'Data analytics upskill program: Foundation course - This course opens on 8 April 2024',
    createdAt: '2024-02-19T02:49:55.384Z',
    updatedAt: '2024-02-19T02:50:13.905Z',
    published: true,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1.1.3',
    subtitle: 'This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: ['_D2-1-1-1', '_D2-1-1', '_D2-1', '_D2', '_D-ZERO'],
  },
  {
    id: '_D2-1-1-1-3-1',
    name: 'Data analytics upskill program: Foundation course - This course opens on 8 April 2024',
    createdAt: '2024-02-19T04:09:08.158Z',
    updatedAt: '2024-03-27T12:03:25.958Z',
    published: false,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1.1.3.1',
    subtitle: 'This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-3',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
    parentSimilarity: 1,
  },
  {
    id: '_D2-1-1-1-2-1-1',
    name: 'Awareness of sustainable practice opportunities: Microcredential  - This course opens on 8 April 2024',
    createdAt: '2024-02-19T04:12:45.968Z',
    updatedAt: '2024-03-27T12:03:25.958Z',
    published: false,
    filename:
      'Microcredential Overview - Awareness of Sustainable practice.docm',
    topic: '2.1.1.1.2.1.1',
    subtitle: 'This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1',
    name: 'Awareness of sustainable practice opportunities: Microcredential  - This course opens on 8 April 2024',
    createdAt: '2024-02-19T04:16:01.158Z',
    updatedAt: '2024-02-19T04:16:10.209Z',
    published: true,
    filename:
      'Microcredential Overview - Awareness of Sustainable practice.docm',
    topic: '2.1.1.1.2.1.1.1',
    subtitle: 'This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1-1',
    name: 'Change management for sustainability in industry: Microcredential  - This course opens on 8 April 2024',
    createdAt: '2024-02-19T04:33:23.905Z',
    updatedAt: '2024-02-19T04:33:41.500Z',
    published: true,
    filename: 'Microcredential Overview - Change management.docm',
    topic: '2.1.1.1.2.1.1.1.1',
    subtitle: 'This course opens on 8 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1-1',
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1-1-1',
    name: 'Change management for sustainability in industry: Microcredential  - This course opens on 25 March 2024',
    createdAt: '2024-02-21T02:12:14.479Z',
    updatedAt: '2024-03-27T12:03:25.958Z',
    published: false,
    filename: 'Microcredential Overview - Change management.docm',
    topic: '2.1.1.1.2.1.1.1.1.1',
    subtitle: 'This course opens on 25 March 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1-1-1',
      '_D2-1-1-1-2-1-1-1',
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-3-2',
    name: 'Data analytics upskill program: Foundation course - This course opens on 15 April 2024',
    createdAt: '2024-02-21T23:37:16.301Z',
    updatedAt: '2024-02-21T23:37:25.855Z',
    published: true,
    filename: 'Course 1 Overview - Foundation Course.docm',
    topic: '2.1.1.1.3.2',
    subtitle: 'This course opens on 15 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-3',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1-1-1-1',
    name: 'Change management for sustainability in industry: Microcredential  - This course opens on 25 March 2024',
    createdAt: '2024-02-21T23:40:27.493Z',
    updatedAt: '2024-02-21T23:41:10.300Z',
    published: true,
    filename: 'Microcredential Overview - Change management.docm',
    topic: '2.1.1.1.2.1.1.1.1.1.1',
    subtitle: 'This course opens on 25 March 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1',
      '_D2-1-1-1-2-1-1-1',
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1-2',
    name: 'Awareness of sustainable practice opportunities: Microcredential  - This course opens on 15 April 2024',
    createdAt: '2024-02-21T23:44:51.388Z',
    updatedAt: '2024-03-05T22:25:37.397Z',
    published: true,
    filename:
      'Microcredential Overview - Awareness of Sustainable practice.docm',
    topic: '2.1.1.1.2.1.1.1.2',
    subtitle: 'This course opens on 15 April 2024',
    publishedAuth0Id: 'auth0|65444715a82b43383572bc9b',
    publishedUserName: 'Tere Latimer',
    isTopic: false,
    industry: 'Insurance',
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1-1',
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1-1-1-1-1',
    name: 'Change management for sustainability in industry TESTTT!!! - Microcredential',
    createdAt: '2024-03-26T22:25:52.216Z',
    updatedAt: '2024-03-26T22:25:56.544Z',
    published: true,
    filename: 'Course Overview - CMSI 2.docm',
    topic: '2.1.1.1.2.1.1.1.1.1.1.1',
    subtitle: 'Microcredential',
    publishedAuth0Id: 'auth0|645aff2f55b6e77b8b09d1a1',
    publishedUserName: 'Andrew Truman',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1',
      '_D2-1-1-1-2-1-1-1',
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1-1-1-1-1-1',
    name: 'Change management for sustainability in industry  - Microcredential',
    createdAt: '2024-03-26T22:31:24.198Z',
    updatedAt: '2024-03-26T22:31:24.198Z',
    published: false,
    filename: 'Course Overview - CMSI.docm',
    topic: '2.1.1.1.2.1.1.1.1.1.1.1.1',
    subtitle: 'Microcredential',
    publishedAuth0Id: 'auth0|645aff2f55b6e77b8b09d1a1',
    publishedUserName: 'Andrew Truman',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1',
      '_D2-1-1-1-2-1-1-1',
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
  {
    id: '_D2-1-1-1-2-1-1-1-1-1-1-1-1-1',
    name: 'Change management for sustainability in industry  - Microcredential',
    createdAt: '2024-03-26T22:31:41.441Z',
    updatedAt: '2024-03-26T22:31:41.441Z',
    published: false,
    filename: 'Course Overview - CMSI.docm',
    topic: '2.1.1.1.2.1.1.1.1.1.1.1.1.1',
    subtitle: 'Microcredential',
    publishedAuth0Id: 'auth0|645aff2f55b6e77b8b09d1a1',
    publishedUserName: 'Andrew Truman',
    isTopic: false,
    clientSpecific: false,
    isLocked: false,
    parentIds: [
      '_D2-1-1-1-2-1-1-1-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1-1',
      '_D2-1-1-1-2-1-1-1-1',
      '_D2-1-1-1-2-1-1-1',
      '_D2-1-1-1-2-1-1',
      '_D2-1-1-1-2-1',
      '_D2-1-1-1-2',
      '_D2-1-1-1',
      '_D2-1-1',
      '_D2-1',
      '_D2',
      '_D-ZERO',
    ],
  },
].map(
  (doc) =>
    ({
      ...doc,
      // parentId: '', // warning: deprecated
      children: [],
      parents: [],
      _TEMP_PARENT_ID: doc.parentIds[0],
      parentSimilarity: 0.99,
      searchSimilarity: null,
      industry: null,
      comments: '',
      fileUrl: `https://s3.ap-southeast-2.amazonaws.com/content.mentem.co/${doc.id}/${doc.filename}`,
      // masterTopicId: doc.parentIds[doc.parentIds.length - 1],
    } as unknown as Document),
)
